import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './SelectInput.module.scss';
import Select from 'react-select' ;

type SelectInputProps = {
    className?: string;
    options: any[];
    placeholder?: string;
    label?: string;
    isRequired?: boolean;
    value?: any;
    onChange?: (newValue: any, actionMeta: any) => void;
    showValidationError?: boolean;
    validate?: (value:any) => string | undefined;
};

export const SelectInput: FC<SelectInputProps> = ({ className = '', options, placeholder, label, isRequired, value, onChange, showValidationError, validate }) => {
    const error = (showValidationError && validate) ? validate(value) : undefined;
    console.log('sv', value); console.log(options);
    return (
        <div className={classNames(styles.select_outer, className, { [styles.error]: error != undefined })}>
            {label && (
                <label className={isRequired ? 'req' : ''}>{label}</label>
            )}
            <Select className={styles.select} classNames={{ 
                control: (state) => state.isFocused ? classNames(styles.control, styles.focused) : styles.control,
                valueContainer: (state) => styles.value_container,
                input: (state) => styles.input,
                indicatorsContainer: (state) => styles.indicators_container,
                dropdownIndicator: (state) => styles.dropdown_indicator,
                indicatorSeparator: (state) => styles.indicator_separator,
                option: (state) => state.isSelected ? classNames(styles.option, styles.selected) : styles.option,
            }} options={options} placeholder={placeholder} value={value} onChange={onChange}></Select>
            {error && (<div className={styles.error_msg}>{error}</div>)}
        </div>
    );
};