import { UserData, UserSurveyData } from "../types/user";
import { fetchApi, handleHttpResponse, requestGet, requestPost } from "./api";

export const createUserSurvey = async (data: UserSurveyData) => {
    return await fetchApi('/v1/user_survey/create', requestPost(data)).then(handleHttpResponse);
}

export const getUserSurvey = async (assessment_id: number, survey_id: string, user_id: string) => {
    return await fetchApi(`/v1/user_survey/pub/getSurvey`, requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id })).then(handleHttpResponse);
}

export const updateUserSurvey = async (data: UserSurveyData) => {
    return await fetchApi('/v1/user_survey/update', requestPost(data)).then(handleHttpResponse);
}

export const completeUserSurvey = async (assessment_id: number, survey_id: string, user_id: string) => {
    return await fetchApi('/v1/user_survey/pub/completeSurvey', requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id })).then(handleHttpResponse);
}

export const createUser = async (data: UserData) => {
    return await fetchApi('/v1/user/create', requestPost(data)).then(handleHttpResponse);
}

export const updateUser = async (data: UserData) => {
    return await fetchApi('/v1/user/update', requestPost(data)).then(handleHttpResponse);
}

export const listUserSurveyStatuses = async () => {
    return await fetchApi(`/v1/user_survey/listStatus`, requestGet()).then(handleHttpResponse);
}

export const deleteUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/delete?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const blockUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/block?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const unblockUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/unblock?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const getUser = async (id: string) => {
    return await fetchApi(`/v1/user/pub/${id}`, requestGet()).then(handleHttpResponse);
}