import React, { useEffect, useState } from 'react';
import styles from './Assessment.module.scss';
import { ReactComponent as PlusIcon } from '../../assets/img/plus-in-circle.svg';
import { Button } from '../../components/Button';
import { emitEvent, validateRequired } from '../../services/utils';
import { getUserInfo, handleHttpError } from '../../services/api';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/list-arrow.svg';
import classNames from 'classnames';
import { AssessmentData } from '../../types/assessment';
import { deleteAssessment, listAssessments, updateAssessment } from '../../services/assessment';
import Moment from 'moment';
import { RateData } from '../../types/rate';
import { listRates } from '../../services/rate';
import { AssessmentStatus } from '../../components/AssessmentStatus';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DropDownIcon } from '../../assets/img/dropdown-toggle.svg';
import { ModalDlg } from '../../components/ModalDlg';
import { TextInput } from '../../components/TextInput';
import { raiseNotice } from '../../components/Notices/Notices';

export function AssessmentList() {
    require('../../assets/css/page.css');

    const [assessments, setAssessments] = useState<AssessmentData[]|undefined>(undefined);
    const [rates, setRates] = useState<RateData[]|undefined>(undefined);

    const [showDelDlg, setShowDelDlg] = useState<boolean>(false);
    const [delObjData, setDelObjData] = useState<any>({ id: undefined, name: '' });
    const [showRenameDlg, setShowRenameDlg] = useState<boolean>(false);
    const [showRenameValidation, setShowRenameValidation] = useState<boolean>(false);
    const [renameObjData, setRenameObjData] = useState<any>({ id: undefined, name: '' });

    const loadAssessments = () => {
        listAssessments(0, 100).then(res => {
            setAssessments(res);
        }).catch(handleHttpError);
    }

    useEffect(() => {
        getUserInfo().then(user => {
            if (!user)
                window.location.href = '/login';
            else {
                loadAssessments();

                window.addEventListener('assessment_created', function(e) {
                    loadAssessments();
                });
            }
        }).catch(handleHttpError);

        listRates(0, 1000).then(res => {
            setRates(res);
        }).catch(handleHttpError);
    }, []);

    const delAssessment = (id: number, name: string) => {
        deleteAssessment(id).then(res => {
            setShowDelDlg(false);
            raiseNotice('info', 'Опрос ' + name + ' удалён');
            loadAssessments();
        }).catch(handleHttpError);
    };

    const renameAssessment = () => {
        setShowRenameValidation(true);

        if (renameObjData.id && renameObjData.name) {
            updateAssessment({ id: renameObjData.id, name: renameObjData.name }).then(res => {
                
                setShowRenameDlg(false);
                raiseNotice('info', 'Опрос переименован');
                loadAssessments();
            }).catch(handleHttpError);
        }
    };

    return (
        <div className='page transparent'>
            <div className={classNames('pagecontent', {'nodata': assessments && assessments.length == 0})}>
                {assessments && assessments.length == 0 && (
                    <div className='nodata-msg'>
                        <PlusIcon />
                        <p>Вы пока не создали ни один<br/>опрос</p>
                        <Button label='Создать опрос' onClick={() => { emitEvent('create_assessment', {}) }}></Button>
                    </div>
                )}
                {assessments && assessments.length > 0 && (
                    <div className={styles.list}>
                        {assessments.map(item => <div key={'assessment-' + item.id} className={styles.assessment_item}>
                            <Dropdown className={styles.btn_actions}>
                                <Dropdown.Toggle>Действия с опросом <DropDownIcon /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { setRenameObjData({ id: item.id, name: item.name }); setShowRenameValidation(false); setShowRenameDlg(true); }}>Переименовать опрос</Dropdown.Item>
                                    {!item.is_paid && (<Dropdown.Item>Сформировать счет</Dropdown.Item>)}
                                    <Dropdown.Item onClick={() => { setDelObjData({ id: item.id, name: '<span class="bold">' + item.name + '</span>, тариф ' + rates?.find(r => r.id == item.rate_id)?.name }); setShowDelDlg(true); }}>Удалить опрос</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <a href={`/assessment/${item.id}`}><h2>{item.name}</h2></a>
                            <div className={styles.info}>
                                <AssessmentStatus assessment={item} />
                                <div className='tag orange'>Тариф <span className='orangetext'>{rates?.find(r => r.id == item.rate_id)?.name}</span></div>
                                <div className='info-date'>{Moment(item.modification_date).format('DD.MM.YYYY')}</div>
                                {item.last_editor && (
                                    <div className='info-person'>{item.last_editor}</div>
                                )}
                            </div>
                            <div className={styles.info_btm}>
                                <div className={styles.info1}>
                                    <h3>Анкетирование</h3>
                                    <div className={styles.items}>
                                        <div className={styles.item}>Всего <span className={styles.bold}>{item.total_user_surveys} / {rates?.find(r => r.id == item.rate_id)?.max_user}</span></div>
                                        <div className={styles.item}>Открыто <span className={styles.bold}>{item.total_user_surveys_active}</span></div>
                                        <div className={styles.item}>Завершено <span className={styles.bold}>{item.total_user_surveys_completed}</span></div>
                                        <div className={styles.item}>Релевантных анкет <span className={styles.bold}>0</span></div>
                                    </div>
                                </div>
                                {(rates?.find(r => r.id == item.rate_id)?.max_user_interviewee ?? 0) > 0 && (
                                <div className={styles.info2}>
                                    <h3>Интервью</h3>
                                    <div className={styles.items}>
                                        <div className={styles.item}>Всего <span className={styles.bold}>{item.total_user_interview_surveys} / {rates?.find(r => r.id == item.rate_id)?.max_user_interviewee}</span></div>
                                        <div className={styles.item}>Назначено встреч <span className={styles.bold}>0</span></div>
                                        <div className={styles.item}>Завершено <span className={styles.bold}>0</span></div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>)}
                    </div>
                )}
                <ModalDlg show={showDelDlg} onClose={() => setShowDelDlg(false)} title='Удаление опроса' submitBtnText='Удалить' onSubmit={() => delAssessment(delObjData.id, delObjData.name)}>
                    <div dangerouslySetInnerHTML={{__html:`Подтвердите удаление опроса ${delObjData.name}`}}></div>
                </ModalDlg>
                <ModalDlg show={showRenameDlg} onClose={() => setShowRenameDlg(false)} title='Переименование опроса' submitBtnText='Переименовать' onSubmit={renameAssessment}>
                    <TextInput label='Название опроса' isRequired showValidationError={showRenameValidation} validate={validateRequired} value={renameObjData.name} onChange={(e) => setRenameObjData((prev:any) => ({...prev, name: e.target.value}))} />
                </ModalDlg>
            </div>
        </div>
    )
}