import React, { FC, useEffect, useState } from 'react';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { ReactComponent as ProfileIcon } from '../../assets/img/profile-menu.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/info.svg';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { deleteCookie, emitEvent, setCookie } from '../../services/utils';
import { getUserInfo, handleHttpError } from '../../services/api';
import { createAssessment } from '../../services/assessment';
import Moment from 'moment';
import { getRate } from '../../services/rate';
import { RateData } from '../../types/rate';
import { useLocation } from 'react-router-dom';

export const Header: FC = () => {
    Moment.locale('ru');

    const [showModalPrices, setShowModalPrices] = useState<boolean>(false);
    const [showModalPricesSelectButtons, setShowModalPricesSelectButtons] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<any>(null);

    const { pathname } = useLocation();

    const handleModalPricesClose = () => {
        setShowModalPrices(false);
        return false;
    };

    const doLogout = () => {
        deleteCookie('auth');
        window.location.href = '/login';
    };

    const tarifSelected = (rate_id: number) => {
        if (userInfo) {
            getRate(rate_id).then((rate:RateData) => {
                if (rate) {
                    createAssessment({
                        name: 'Оценка компании ' + userInfo.company_name,
                        account_num: '',
                        company_num: '',
                        start: Moment().format('YYYY-MM-DD'),
                        company_id: userInfo.company_id,
                        rate_id: rate_id,
                        is_paid: rate.price < 1e-3,
                        is_finished: false,
                        reports: [],
                        result: 0
                    }).then(res => {
                        setShowModalPrices(false);
                        emitEvent('assessment_created', {});
                    }).catch(handleHttpError);
                }
            }).catch(handleHttpError);
        }
    };

    useEffect(() => {
        getUserInfo().then(res => setUserInfo(res));

        window.addEventListener('create_assessment', function (e) {
            setShowModalPrices(true);
        });
    }, []);

    return (
        <>
        <header className={styles.header}>
            <a href="/" className={styles.sitename}>РДЦ</a>
            <ul className={styles.topmenu}>
                <li><a className={classNames({ [styles.active]: pathname.indexOf('/company') == -1 })} href="/">Опросы</a></li>
                <li><a className={classNames({ [styles.active]: pathname.indexOf('/company') != -1 })} href="/company">Данные компании</a></li>
                <li><a className={classNames({ [styles.active]: false })} href="#" onClick={() => { setShowModalPricesSelectButtons(false); setShowModalPrices(true); }}>Тарифы и платные услуги</a></li>
            </ul>
            <div className={styles.sep}></div>
            <Button type='orange' label='Создать опрос' onClick={() => { setShowModalPricesSelectButtons(true); setShowModalPrices(true); }} />
            {userInfo && (
                <Dropdown>
                    <Dropdown.Toggle><ProfileIcon /></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={doLogout}>Выход</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </header>
        <Modal show={showModalPrices} backdrop={true} onHide={handleModalPricesClose} dialogClassName={styles.dlg_prices}>
            <Modal.Header closeButton><Modal.Title>Доступные тарифы и платные услуги</Modal.Title></Modal.Header>
            <Modal.Body>
                <div className={styles.prices_wrap}>
                    <div className={styles.prices_col}>
                        <div className={styles.title}>Базовый<br/>Бесплатно</div>
                        <div>
                            <div className={classNames(styles.type, styles.grey)}>Автоматическая оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>50 респондентов</li>
                                <li>экспресс-анкета (38 вопросов)</li>
                                <li>единая оценка без разделения на домены данных</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Отчёт об оценке</small>
                            <ul className={styles.checks}>
                                <li>соответствие уровню</li>
                                <li>численная оценка</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>100 респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>полная анкета (136 вопросов)</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>отчет с рекомендациями</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>проведение интервью</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' type='empty' onClick={() => tarifSelected(1)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={styles.prices_col}>
                        <div className={styles.title}>Экспресс<br/>25 000 ₽</div>
                        <div>
                            <div className={classNames(styles.type, styles.orange)}>Экспертная оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>100 респондентов</li>
                                <li>5 интервью</li>
                                <li>3 домена данных</li>
                                <li>экспресс-анкета (38 вопросов)</li>
                                <li>менеджер сопровождения</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Презентация с рекомендациями</small>
                            <ul className={styles.checks}>
                                <li>уровень и численная оценка</li>
                                <li className={styles.plus}>комментарии и пояснения</li>
                                <li className={styles.plus}>рекомендации по развитию</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>1000 респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>15 интервью</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>5 доменов данных</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>полная анкета (136 вопросов)</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' onClick={() => tarifSelected(2)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={styles.prices_col}>
                        <div className={styles.title}>Стандарт<br/>50 000 ₽</div>
                        <div>
                            <div className={classNames(styles.type, styles.orange)}>Экспертная оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>1000 респондентов</li>
                                <li>15 интервью</li>
                                <li>5 доменов данных</li>
                                <li>полная анкета (136 вопросов)</li>
                                <li>менеджер сопровождения</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Презентация с рекомендациями</small>
                            <ul className={styles.checks}>
                                <li>уровень, оценка, комментарии</li>
                                <li>рекомендации по развитию</li>
                                <li className={styles.plus}>дорожная карта</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>1000+ респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>доп. интервью</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>доп. домены данных</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>индивидуальная анкета (совмещение с внутренними опросами компании)</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' onClick={() => tarifSelected(3)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={classNames(styles.prices_col, styles.orange)}>
                        <div className={styles.title}>Расширенный</div>
                        <div className={classNames(styles.title, styles.white)}>Выберите этот вариант, если вам нужны особые условия</div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <a href="mailto:info@bssg.ru?subject=Запрос на расширенную оценку" className={styles.link_send_request}>Отправить запрос</a>
                            </div>
                        </>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
};