import React, { FC, useEffect, useState } from 'react';
import styles from './HeaderUserSurvey.module.scss';
import classNames from 'classnames';
import { ProgressBar } from '../ProgressBar';
import { Button } from '../Button';

export const HeaderUserSurvey: FC = () => {
    return (
        <></>
    )
}