import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useRouteId } from 'react-router/dist/lib/hooks';
import styles from './IconButton.module.scss';

type IconButtonProps = {
    icon: ReactNode;
};

export const IconButton: FC<IconButtonProps> = ({ icon }) => {
    
    return (
        <button className={styles.button} type='button'>{icon}</button>
    );
};