import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './TextArea.module.scss';

type TextAreaProps = {
    label?: string;
    isRequired?: boolean;
    placeholder?: string | undefined;
    value?: string | undefined;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    showValidationError?: boolean;
    validate?: (value:any) => string | undefined;
};

export const TextArea: FC<TextAreaProps> = ({ placeholder, value, label, isRequired, onChange = () => {}, showValidationError, validate }) => {
    const error = (showValidationError && validate) ? validate(value) : undefined;
    return (
        <div className={classNames(styles.wrap, {[styles.error]: error != undefined })}>
            {label && (
                <label className={isRequired ? 'req' : ''}>{label}</label>
            )}
            <textarea className={styles.input} value={value} placeholder={placeholder} onChange={onChange} />
            {error && (<div className={styles.error_msg}>{error}</div>)}
        </div>
    );
};