import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './PasswordInput.module.scss';
import { ReactComponent as ShowPasswordIcon } from '../../assets/img/passw-show.svg';

type PasswordInputProps = {
    label?: string;
    defaultValue?: string | undefined;
    placeholder?: string | undefined;
    value?: string | undefined;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput: FC<PasswordInputProps> = ({ defaultValue, placeholder, value, label, onChange = () => {} }) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    return (
        <div className={styles.wrap}>
            {label && (
                <label>{label}</label>
            )}
            <div className={styles.input_wrap}>
                <input className={styles.input} type={ passwordVisible ? 'text' : 'password'} value={value} defaultValue={defaultValue} placeholder={placeholder} onChange={onChange} />
                <button className={styles.btn_show_password} onClick={() => { setPasswordVisible(!passwordVisible); }}><ShowPasswordIcon /></button>
            </div>
        </div>
    );
};