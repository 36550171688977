import React, { ChangeEvent, FC, MouseEventHandler, useEffect, useState } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

type ButtonProps = {
    label: string;
    type?: string;
    onClick?: MouseEventHandler;
    doValidate?: boolean;
};

export const Button: FC<ButtonProps> = ({ label, type, onClick }) => {
    
    return (
        <button className={classNames(styles[type ?? 'general'], styles.button)} type='button' onClick={onClick}>{label}</button>
    );
};