import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useRouteId } from 'react-router/dist/lib/hooks';
import styles from './Checkbox.module.scss';
import { uuid } from '../../services/utils';
import classNames from 'classnames';

type CheckboxProps = {
    label: string;
    style?: string;
    onChange?: ChangeEventHandler;
    defaultChecked?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({ label, style, onChange, defaultChecked }) => {
    const [id, setId] = useState<string>('cb-' + uuid());
    
    return (
        <div className={classNames(styles.checkbox, styles[style ?? 'general'])}>
            <input className={styles.input} type='checkbox' id={id} name={id} value='1' onChange={onChange} defaultChecked={defaultChecked} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};