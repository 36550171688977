import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
    progress?: number;
    maxProgress?: number;
    className?: string;
    color?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress = 0, maxProgress = 100, className = '', color = '#000000' }) => {
    
    return (
        <div className={classNames(styles.progress_outer, className)}>
            <div className={styles.progress_inner} style={{ width: (maxProgress == 0 ? 0 : (progress * 100 / maxProgress)) + '%', background: color }}></div>
        </div>
    );
};